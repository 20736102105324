<template>
    <div class="content-main">
        <!--title区-->
        <div>
            <div class="content-header">
                <h2>比赛列表</h2>
            </div>
            <div>
                <el-divider></el-divider>
            </div>
        </div>
        <div class="content-title">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
                <el-breadcrumb-item >比赛列表</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="t-search">
               <el-row>
                   <el-col :span="6">比赛类型:</el-col>
                   <el-col :span="18">
                        <el-select class="m-full" v-model="search.type">
                            <el-option
                                    v-for="item in $webConfig.tournamentType"
                                    :key="item.type"
                                    :label="item.name"
                                    :value="item.type"
                            ></el-option>
                        </el-select>
                   </el-col>
               </el-row>
                <el-row>
                    <el-col :span="6">比赛时间:</el-col>
                    <el-col :span="18">
                        <el-date-picker
                                style="width: 100%"
                                v-model="search.tournamentDate"
                                type="date"
                                placeholder="选择日期">
                        </el-date-picker>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">比赛地点:</el-col>
                    <el-col :span="18">
                        <el-cascader
                                size="large"
                                :options="areaOptions"
                                v-model="search.area"
                                @change="handleAreaChange"
                                class="m-full"
                        >
                        </el-cascader>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12" >
                        <el-button type="success" @click="getList">筛选</el-button>
                        <el-button
                                @click="search={area:[],type:'',tournamentDate:''}"
                        >重置
                        </el-button>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!--列表区-->
        <div class="content-list">
            <div>
                <el-divider>报名中比赛列表</el-divider>
            </div>
            <div class="list-header">
                <el-row>
                    <el-col :span="4">比赛时间</el-col>
                    <el-col :span="4">比赛类型</el-col>
                    <el-col :span="6">比赛名字</el-col>
                    <el-col :span="6">比赛地址</el-col>
                    <el-col :span="4">举办者</el-col>
                </el-row>
            </div>
            <div class="list-area">
                <div class="list-item">
                    <div v-for="item in dataList" :key="item.id">
                        <router-link :to="{path:'/index/tournamentDetail',query:{id:item.id,info:item}}">
                            <el-row>
                                <el-col :span="4">{{$moment(item.started_at).format("YYYY-MM-DD")}}</el-col>
                                <el-col :span="4">{{$webConfig.tournamentType[item.type].name}}</el-col>
                                <el-col :span="6">{{item.name}}</el-col>
                                <el-col :span="6">{{item.address}}</el-col>
                                <el-col :span="4">{{item.organizer.show_name}}</el-col>
                            </el-row>
                            <el-divider></el-divider>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <!--分页区-->
        <div style="width: 100%;margin: 0 auto;text-align: center">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="pageSizes"
                    :page-size="pageSize"
                    layout="prev, pager, next"
                    :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                pageSize:this.$webConfig.pages.pageSize,
                pageSizes:this.$webConfig.pages.pageSizes,
                currentPage:1,
                total:0,
                dataList:[],
                areaOptions: this.$webConfig.areaData ,
                search:{
                    area:[],
                    tournamentDate:"",
                    type:"",
                }
            }
        },
        methods: {
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
                this.currentPage = val;
                this.getList();
            },
            handleAreaChange(val){
                this.search.area = val;
            },
            getList(){
                let page = this.currentPage;
                let limit = this.pageSize;
                let search = this.search;
                this.$axios.post("/site/siteTournament/getList",{page,limit,search})
                    .then((res)=>{
                        if (res.data.code == 1000){
                            this.dataList = res.data.data.list;
                            this.total = res.data.data.total;
                        }else{
                            this.$message.error(res.data.msg)
                        }
                })
            }
        },
        created() {
            this.getList();
        }
    }
</script>

<style scoped>
    .content-main{
        width: 100%;
        margin: 0 auto;
        background-color: white;
        padding: 20px 0;
    }
    .content-header{
        text-align: center;
    }
    .content-title{
        width: 100%;
        margin: 0 auto;
    }
    .content-title >div:last-child{
        padding: 15px;
        text-align: center;
        letter-spacing: 2px;
        line-height: 30px;
    }
    .t-search > div{
        margin: 15px 0;
    }
    .content-list{
        font-size: 24px;
    }
    .list-header{
        width: 98%;
        margin: 0 auto;
        font-size: 12px;
        text-align: center;
        color: white;
        background-color: #dd1b3e;
        line-height: 50px;
        font-weight: bold;
    }
    .list-area {
        width: 100%;
        margin: 0 auto;
        margin-top: 10px;
    }
    .list-item a{
        text-decoration: none;
        color: #636b6f;
    }
    .list-item a:visited {
        color: #636b6f;
    }
    .list-item a:hover {
        color: red;
        font-weight: bold;
        font-size: 18px;
    }
    .list-item > div{
        width: 98%;
        font-size: 16px;
        margin: 20px 0;
        text-align: center;
    }



</style>
